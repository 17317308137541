
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車・プレミアムカー】購入前に知っておきたい！国産車や外車の人気メーカーの特徴
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            国産車から外車まで様々なメーカーがありますので、どのメーカーのプレミアムカーが自分には合っているのかわからないという方は多いでしょう。中古車を購入する前に、各メーカーの特徴を把握しておくことが大切です。
            <div class="section-title">
              <span >国産車・外車の人気メーカーをご紹介！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <span>プレミアムカーの中古車の購入をご検討の際は、国産車や外車メーカーの特徴や人気車種を押さえておくことが重要です。</span>
                    <h3 class="heading-number" data-number="◆">国産車の人気メーカー</h3>
                    <p class="u-m0 u-pb1em">
                      日本の自動車メーカーは、「高品質」だと世界的に評価されています。特に人気のある国産車メーカーの特徴を見ていきましょう。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>トヨタ</h4>
                    <p class="u-m0 u-pb1em">
                      トヨタは、世界最大の自動車メーカーとして知られており、信頼性と耐久性を重視した車種が豊富です。ハイブリッド車のパイオニアでもあり、環境に配慮した技術開発も進められています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      ラグジュアリーブランドのレクサスも、トヨタが展開するブランドです。トヨタの人気の中古車として、ランドクルーザーやヴェルファイア、クラウンなどが挙げられます。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>ホンダ</h4>
                    <p class="u-m0 u-pb1em">
                      ホンダは、革新的な技術とスポーティな走りを追求するメーカーです。エンジン技術に定評があり、二輪車からF1まで幅広いラインナップを展開しています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      ホンダの人気の中古車は、2シーター・オープンスポーツカーであるS660をはじめ、ミッドサイズセダンの中でも人気のあるアコード、スポーティな走行性能を誇るコンパクトカーのシビックなどです。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/white-honda-civic-r.jpg" alt="Image">
                  </div>
              </div>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>日産</h4>
              <p class="u-m0 u-pb1em">
                日産は、電気自動車のリーフや高性能スポーツカーのGT-Rなど、多様なニーズに応える車種を提供するメーカーです。特に、電気自動車の分野では先駆者として知られており、現在も技術開発を進めています。
              </p>
              <p class="u-m0 u-pb1em">
                日産の人気の中古車として、パワフルなエンジンと先進的な駆動技術を搭載したGT-Rがあります。その他にも、日産のフラッグシップモデルのスカイラインや豪華な内装とハイブリッド技術を組み合わせたフーガ、高級ラインのインフィニティなども人気です。
              </p>
              <h3 class="heading-number" data-number="◆">外車の人気メーカー</h3>
              <p class="u-m0 u-pb1em">
                次に、人気の外車メーカーをご紹介いたします。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデス・ベンツ</h4>
              <p class="u-m0 u-pb1em">
                安全性を重視した設計をしているメルセデス・ベンツは、高級車の代表的なブランドです。コンパクトながら高級感のあるCクラスや快適な乗り心地が魅力のEクラスなどが特に人気を集めています。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>BMW</h4>
              <p class="u-m0 u-pb1em">
                自動車やバイク、エンジンを製造しているBMWは、パワフルなエンジンと精密なハンドリングが魅力のドイツの高級メーカーとして知られています。
              </p>
              <p class="u-m0 u-pb1em">
                コンパクトながら高性能で洗練したデザインが特徴の3シリーズや高級感あふれる内装と先進的なテクノロジーが特徴の5シリーズなどが人気の車種です。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>アウディ</h4>
              <p class="u-m0 u-pb1em">
                ドイツの高級車メーカー・アウディは、高品質な内装と先進的な技術を誇るメーカーで、独自の四輪駆動システム「クワトロ」が有名です。アウディの中古車で人気なのが、コンパクトで低燃費が魅力のA3やビジネスシーンでも活躍する高級ラインのA4などです。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                希少価値が高い中古車をお探しの方、人気メーカーの中古車をお探しの方は、ぜひcar shop MIXにご相談ください。国産車、外車を問わず幅広いメーカーのプレミアムカーをご用意しています。
              </p>
              <p class="u-m0 u-pb1em">
                また、自動車板金・塗装・カークリーニング・オイル交換など車両メンテナンスも行っています。車に関するお悩みがございましたら、お気軽にお問い合わせください。
              </p>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせはこちら</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >人気メーカーのプレミアムカーを販売！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      自動車には様々なメーカーがありますが、国産車の場合はトヨタやホンダ、日産、外車メーカーの場合は、メルセデス・ベンツやBMW、アウディなどが人気です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      人気メーカーのプレミアムカーの購入を検討する場合は、新車だけでなく中古車も選択肢に入れてみましょう。中古車を選ぶ際は、予算はもちろん、求める性能やデザインなどをしっかりとイメージすることが大切です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      ご紹介した人気メーカーの特徴や人気車種を参考にしながら、お気に入りの一台を探してみてください。
                    </p>
                    <p class="u-m0 u-pb1em">
                      プレミアムカーの中古車をお探しでしたら、car shop MIXにお任せください。car shop MIXは、一般的に市場に出回らないような車種やモデルを集めるノウハウがあります。低走行車や25年規制が解除されるような車を中心に、幅広いモデルを取り扱っているのが特徴です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      国産車、外車など様々なメーカーの中古車をご用意していますので、レアな車種やモデルをお探しの方は、お気軽にお問い合わせください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="プレミアムカーの中古車を探すイメージ" src="@/assets/img/column-maker-mt/premium-car-used-car-image.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <column-footer :title="'プレミアムカーを販売！国産車・外車の中古車をお探しならcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-01.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
